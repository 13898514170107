import "regenerator-runtime/runtime"
import { getOrderByReference } from "../domain/datasources/orders.remote"

export const handleGetOrderByReference = (reference, callback) => {
  async function asyncCall() {
    const response = await getOrderByReference(reference)
    if (response.state === "success") {
      callback(null, response.orderInfo)
    }
  }
  return asyncCall()
}
