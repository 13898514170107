import React, { useState, useEffect } from "react"
import { handleGetOrderByReference } from "../../data/usecases/getorderbyreference"
import { getQueryParam } from "../../data/managers/getqueryparam"
import { navigate } from "gatsby"
import { currencyToSymbol } from "../../data/managers/currencytosymbol"
import RealBoostLogo from "./checkout/components/realboostlogo"
import Layout from "../chunks/layout"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

const ThankYou = () => {
  const reference = getQueryParam("reference", useLocation())
  const [orderInfo, setOrderInfo] = useState(null)

  function delete_cookie(name, path) {
    document.cookie =
      name + "=; Path=/" + path + "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
  }

  useEffect(() => {
    if (process.env.APP_ENV === "prod") {
      if (orderInfo) {
        const { items } = orderInfo
        window.dataLayer = window.dataLayer || []
        const data = {
          transactionId: items[0].order_reference,
          transactionAffiliation: "Boostik",
          transactionTotal: items[0].total_amount,
          transactionTax: 0,
          transactionShipping: 0,
          transactionProducts: items[0].order_items.map(item => ({
            sku: item.reference,
            name: item.item_name,
            category: item.category,
            price: item.unit_price,
            quantity: item.quantity,
          })),
        }
        window.dataLayer.push(data)
      }
    }
  }, [orderInfo])

  useEffect(() => {
    if (reference) {
      handleGetOrderByReference(reference, (error, order) => {
        setOrderInfo(order)
        delete_cookie("customer.email", "")
        delete_cookie("customer.username", "")
        delete_cookie("customer.url", "")
        delete_cookie("cart_id", "checkout")
        delete_cookie("cart_id", "thankyou")
        delete_cookie("cart_id", "")
      })
    }
  }, [reference])
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Checkout | Boostik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="min-w-screen min-h-screen flex items-center justify-center px-5 pb-10 pt-16 background-section-gray">
        <div className="background-checkout-position background-gradient-home">
          <div
            className="background-gray-wave-separator"
            style={{ position: "absolute", bottom: "0", width: "100%" }}
          ></div>
        </div>
        <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700 md:max-w-lg z-10">
          <RealBoostLogo />
          <div>
            <section className="pricing-area ptb-100 pt-0">
              <div>
                <div className="mb-10">
                  <h1 className="text-center color-home font-font-bold text-2xl">
                    <i className={`bx bx-badge-check text-green-500`}></i> Thank
                    you{" "}
                    {orderInfo && orderInfo.items[0] && orderInfo.items[0].name}
                    !
                  </h1>
                </div>
                <div className="w-full text-right">
                  <div>
                    Order #
                    {orderInfo &&
                      orderInfo.items[0] &&
                      orderInfo.items[0].order_reference}
                  </div>
                </div>
                <div className="p-5 bg-white border-2 border-gray-50 rounded-md">
                  <div className="font-bold">Contact Information</div>
                  <div>
                    {orderInfo &&
                      orderInfo.items[0] &&
                      orderInfo.items[0].email}
                  </div>
                </div>
                <div className="p-5 bg-gray-50 mt-2">
                  <div className="font-bold">Campaign Details</div>
                  {orderInfo &&
                    orderInfo.items[0] &&
                    orderInfo.items[0].order_items.map((item, index) => (
                      <div className="flex justify-between" key={index}>
                        <div>
                          <span className="capitalize">{item.category}</span>{" "}
                          {item.item_name}
                        </div>
                        <div>
                          {currencyToSymbol(item.currency)}
                          {item.line_price}
                        </div>
                      </div>
                    ))}
                  <div className="flex justify-between mt-10">
                    <div>Discount:</div>
                    <div>
                      {orderInfo &&
                        orderInfo.items[0] &&
                        currencyToSymbol(orderInfo.items[0].currency)}
                      {orderInfo &&
                        orderInfo.items[0] &&
                        orderInfo.items[0].total_discount_amount}
                    </div>
                  </div>
                  <div className="flex justify-between font-bold">
                    <div>Total (inc. Tax):</div>
                    <div>
                      {orderInfo &&
                        orderInfo.items[0] &&
                        currencyToSymbol(orderInfo.items[0].currency)}
                      {orderInfo &&
                        orderInfo.items[0] &&
                        orderInfo.items[0].total_amount}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="block w-full max-w-xs mx-auto mt-10 py-1 text-center text-white">
                    <button
                      onClick={() => navigate(`/tiktok/followers`)}
                      className="rounded-full py-1 px-8 background-home"
                    >
                      Back to Boostik
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYou
